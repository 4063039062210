
import React, { memo } from "react";
import styles from "./Product.module.css";
import Head from "next/head";
import Link from "next/link";
import Image from "/components/CustomImage";
import dynamic from "next/dynamic";
import WishlistButton from "../WishlistButton/WishlistButton";
import FormLoader from "/components/ui/FormLoader";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as PCarousel } from "react-responsive-carousel";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import JsonLdProduct from "../Schema/JsonLdProduct";
import { useProduct } from "./hooks/useProduct";
import Script from 'next/script';
import NetQuantity from './NetQuantity';
import PWABenefitsSection from "./ProductSubComponents/PWABenefits";
import OurCertifcatesSection from "./ProductSubComponents/OurCertificates";
import KamaBlogSection from "./ProductSubComponents/KamaBlogSection";
import ExploreBlogSection from "./ProductSubComponents/ExploreBlogSection";
import IngredientsSection from "./ProductSubComponents/IngredientsSection";
import ProductReviewComponent from "./ProductSubComponents/ReviewsComponent";
import OfferPincodeSection from "./ProductSubComponents/OfferPincodeSection";
import YoutubeContentSection from "./ProductSubComponents/YoutubeContentSection";
import BuyItWithProducts from "./ProductSubComponents/BuyItWithProducts";
import RelatedProducts from "./ProductSubComponents/RelatedProducts";
import RecommendedProducts from "./ProductSubComponents/RecommendedProducts"
import FAQSection from "./ProductSubComponents/FAQSection";
import FAQs from "./ProductSubComponents/FAQs";
import AddQuestionSection from "./ProductSubComponents/AddQuestionSection";
import RecentlyViewedSection from "./ProductSubComponents/RecentlyViewedSection";

const AnniversaryTimer = dynamic(() => import('../AnniversaryTimer'))
const SimplModal = dynamic(() => import('./ProductSubComponents/SimplModal'))
const NotifyModal = dynamic(() => import('../NotifyModal'))

import youtube_icon_red from '~/public/images/youtube_icon_red.inline.svg';
import zoom from '~/public/images/zoom.inline.svg';
import zoomNew from '~/public/images/zoom-In.inline.svg'
import info from '~/public/images/info.inline.svg';
import facebook from '~/public/images/fb_upd.inline.svg';
import twitter from '~/public/images/x_upd.inline.svg';
import star_white from '~/public/images/star-white.inline.svg';
import simpl_icon from '~/public/images/simpl-icon.inline.svg';
import desk_bag_white from '~/public/images/desk-bag-white.inline.svg';
import right_icon2 from '~/public/images/right-icon2.inline.svg';
import { generateBreadcrumbs } from "/lib/services/helpers/generateBreadCrumbs";
import ATCBuyNowButton from "./ATCBuyNowButton";
import { useNotifyModal } from "/lib/hooks/useNotifyModal";
import NewProductReviewComponent from "./ProductSubComponents/NewReviewsComponent";
import OfferAndRewards from "./ProductSubComponents/OfferAndRewards";
import OverviewSection from "./ProductSubComponents/Overview";
import RoutineProduct from "./ProductSubComponents/RoutineProduct";
import ProductInformation from "./ProductSubComponents/ProductInformation";
import HowToApply from "./ProductSubComponents/HowToApply";
import SaffronSection from "./ProductSubComponents/SaffronSection";
import YouthGlowSection from "./ProductSubComponents/YouthGlowSection";
import NewInfoSection from "./ProductSubComponents/NewInfoSection";

const RecentlyViewedMemoized = memo(RecentlyViewedSection)
const AddQuestionMemoized = memo(AddQuestionSection)
const FAQSectionMemoized = memo(FAQSection);
const FAQRedesignSectionMemoized = memo(FAQs);
const RelatedProductsMemoized = memo(RelatedProducts)
const RecommnededProductsMemoized = memo(RecommendedProducts)
const BuyItWithProductsMemoized = memo(BuyItWithProducts)
const NetQuantityMemoized = memo(NetQuantity)
const OfferPincodeMemoized = memo(OfferPincodeSection)
const IngredientsMemoized = memo(IngredientsSection)
const KamaBlogMemoized = memo(KamaBlogSection)
const OfferAndRewardsMemoized = memo(OfferAndRewards)
const OverviewSectionMemoized = memo(OverviewSection)
const HowToApplyMemoized = memo(HowToApply)
const ExploreBlogMemorized = memo(ExploreBlogSection)
const SaffronMemorized = memo(SaffronSection)
const YouthGlowmemorized = memo(YouthGlowSection)
const NewInfoSectionMemorized = memo(NewInfoSection)

export const Product = ({ urlKey, pdp, isSsrMobile }) => {
  const data = pdp.data
  const product = data?.products?.items?.find?.(obj => obj.type_id === "simple") || data?.products?.items?.[0];

  const enableVariantData = product?.pdp_variant_data_change == 1 ? true : false;

  const {
    router,
    breadCrumbUrl,
    currency,
    preFixUrl,
    websiteID,
    eventCommonObj,
    productprice,
    saleproductprice,
    productsize,
    childsku,
    variantLabel,
    mediaList,
    buyitwithproducts,
    isMobileType,
    config,
    checkedState,
    crosssellCount,
    zoomvideos,
    zoomimgs,
    showLoader,
    ButtonText,
    open,
    successMessage,
    cartMessage,
    bSnackbarVisible,
    showPDPMsg,
    classes,
    asPath,
    recentview,
    part,
    canonicalurl,
    modalStyle,
    BuyNowButtonText,
    showModal,
    photoIndex,
    isOpen,
    count,
    productcategory,
    productDetails,
    pdpOffers,
    setShowLoader,
    selectSize,
    addToBag,
    buyToBag,
    addCrossSellToBag,
    handleOnChange,
    handleSnackClose,
    handleCloseModal,
    showTermsModal,
    zoomopen,
    navigate,
    inc,
    dec,
    showYoutube,
    openShare,
    handleShareClick
  } = useProduct({ product, isSsrMobile, enableVariantData })

  const { showNotifyModal, handleModalClose } = useNotifyModal();

  const cartLink = (websiteID == 1) ? "/checkout/cart" : "/international/checkout/cart"

  if (!data) return <div>⌚️ Loading...</div>;

  if (!data?.products?.items?.[0]) return "Product Not Found";

  const renderCustomThumbs = () => {
    let thumbList = []
    thumbList = mediaList
      ?.slice()
      ?.sort((a, b) => a.position - b.position)
      ?.sort((a, b) => a.__typename.localeCompare(b.__typename))
      ?.map((image, index) =>
        image?.video_content == null ? (
          <Image
            width={64}
            height={64}
            className={styles.pdpBanner}
            key={index}
            loading={index == 0 ? 'eager' : 'lazy'}
            src={image.url}
            alt={image.label} />
        ) : (
          <div key={index} className={styles.youtube_icon_thumbs}>
            <div className={styles.youtube_icon}>
              <img loading='lazy' src={youtube_icon_red} alt="youtube_icon_red" width="16" height="11" />
            </div >
            <Image
              width={64}
              height={64}
              className={styles.pdpBanner}
              loading={index == 0 ? 'eager' : 'lazy'}
              src={image.url}
              alt={image.label} />
          </div>
        )
      );

    return thumbList;
  };

  return (
    <>
      <Head>
        {
          (asPath.includes('/international')) ? <>
            <link rel="alternate" hrefLang="en-us" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-ca" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-au" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-ae" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-sg" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            {(product.hreflangslug && product.hreflangslug.label) ? <link rel="alternate" hrefLang="en-in" href={product.hreflangslug.label} /> : null}
            {(product.hreflangslug && product.hreflangslug.label) ? <link rel="alternate" hrefLang="x-default" href={product.hreflangslug.label} /> : null}

          </> : <>
            <link rel="alternate" hrefLang="en-in" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="hi-in" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-gb" href={`${process.env.NEXTINTERNATION_URL}/uk/en_GB`} />
            {(product?.redirect_url) ? <link rel="alternate" hrefLang="en-us" href={product.redirect_url} /> : null}
            {(product?.hreflang_url) ? <link rel="alternate" hrefLang="en-gb" href={product.hreflang_url} /> : null}
          </>
        }

        <title>{product?.name}</title>

        {product.meta_title ? <title>{product.meta_title}</title> : null}
        {product.meta_title ? (
          <meta name="title" content={product.meta_title} />
        ) : null}
        {product.meta_description ? (
          <meta name="description" content={product.meta_description} />
        ) : null}
        {product.meta_keyword ? (
          <meta name="keywords" content={product.meta_keyword} />
        ) : null}
        {product.meta_title ? (
          <meta property="og:title" content={product.meta_title} />
        ) : null}
        {product.meta_description ? (
          <meta property="og:description" content={product.meta_description} />
        ) : null}
        {product.small_image && product.small_image.url ? (
          <><meta property="og:image" content={product.small_image.url} /><meta property="twitter:image" content={product.small_image.url} /></>
        ) : null}
        {product.meta_description ? (
          <meta
            property="twitter:description"
            content={product.meta_description}
          />
        ) : null}
        {product.meta_title ? (
          <meta property="twitter:title" content={product.meta_title} />
        ) : null}
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        {
          (canonicalurl) ? <link rel="canonical" href={canonicalurl} /> : <link rel="canonical" href={`${process.env.NEXTAUTH_URL}${asPath}`}
          />
        }

      </Head>

      {/* <Script
        async
        strategy="lazyOnload"
        src="https://rtg.l10.agency/resources/content/admit_kamaayuverda.js"
        integrity="sha384-ZFea/2gOlLb++GyhwQrGFQED8VIsWj9SO9VL1hr6acAUecKwzs8WI3M1VGWmb032"
        crossOrigin="anonymous"
      /> 
      
      /> */}

      <Script src="https://static-cdn.trackier.com/js/trackier-websdk-init.js" />

      <JsonLdProduct ProductSuffix={pdp.data.storeConfig.product_url_suffix} data={product} />

      {bSnackbarVisible ? (
        <Snackbar
          open={open}
          autoHideDuration={1500}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            severity={successMessage == true ? "success" : "error"}
          >
            <div dangerouslySetInnerHTML={{ __html: cartMessage }} />
          </Alert>
        </Snackbar>
      ) : null}

      <FormLoader show={showLoader} />

      {showNotifyModal ? <NotifyModal
        handleModalClose={handleModalClose}
        redirectUrl={product?.redirect_url}
        hreflangUrl={product?.hreflang_url}
        isOpen={showNotifyModal}
        className='notifyContent'
      /> : null}

      {showModal ? <SimplModal handleCloseModal={handleCloseModal} /> : null}

      {!product?.layout_switch && ((config?.anniversary_promotion8_text?.split(',')?.includes(product.sku)) && config.anniversary_timerdatetime_timer_end_time && new Date(config.anniversary_timerdatetime_timer_end_time) > new Date()) ?
        <AnniversaryTimer
          duration={config.anniversary_timerdatetime_timer_end_time}
          timerMsg={config.anniversary_timerdatetime_catalog_msg}
          styles={styles}
          websiteID={websiteID} /> : null
      }

      {/* Breadcrumb */}
      <div className={product?.layout_switch ? 'container_productitem' : 'container'}>
        {!isMobileType ? (
          <div className="breadcrumbs_sec">
            <nav>
              <ol>
                {generateBreadcrumbs(router, breadCrumbUrl, true)?.map((list, id) => (
                  <li key={id}><Link href={list.href}>{list.title}</Link></li>
                ))}
              </ol>
            </nav>
          </div>
        ) : null}
      </div>

      <div className={product?.layout_switch ? `container_productLayoutNew ${styles.productLayoutNew}` : styles.product}>
        {isOpen ? (
          <Lightbox
            mainSrc={zoomimgs[photoIndex]}
            nextSrc={zoomimgs[(photoIndex + 1) % zoomimgs.length]}
            prevSrc={zoomimgs[(photoIndex + zoomimgs.length - 1) % zoomimgs.length]}
            onCloseRequest={() => zoomopen(false, 0)}
            onMovePrevRequest={() => navigate((photoIndex + zoomimgs.length - 1) % zoomimgs.length)}
            onMoveNextRequest={() => navigate((photoIndex + 1) % zoomimgs.length)}
          />
        ) : null}

        <div className={product?.layout_switch ? styles.product_container : "container"}>
          <div className="row">
            {product?.layout_switch ? (
              <div className={`col-12 col-md-6 p-col-0 ${styles.carousal_sec}`}>
                <div className={`productLayoutGallery gallery ${styles["gallery"]}`}>
                  {product && mediaList ? (
                    <PCarousel
                      showArrows
                      showStatus={false}
                      showIndicators
                      showThumbs
                      autoPlay={false}
                      transitionTime={500}
                      emulateTouch
                      swipeable
                      draggable
                      swipeScrollTolerance={5}
                      preventMovementUntilSwipeScrollTolerance
                      showDots={false}
                      renderThumbs={renderCustomThumbs}
                    >
                      { zoomimgs?.map((image, index) =>
                        <span key={index}>
                          {!isMobileType && <a className="zoom-icon" onClick={() => zoomopen(true, index)}>
                            <img priority={index == 0 || false} width={40} height={40} src={zoomNew} alt="zoom icon" />
                          </a>}
                          <Image
                            className={styles.pdpBanner}
                            onDoubleClick={() => zoomopen(true, index)}
                            loading={index === 0 ? 'eager' : 'lazy'}
                            width={600}
                            height={600}
                            src={image}
                            alt={product.name} />
                        </span>
                      )}
                      {zoomvideos?.map((video, index) =>
                        <div key={index} className={styles.youtube_iframe} id={`youtube_${index}`}>
                          <div className={styles.youtube_icon}>
                            <img className={styles.youtube_icon_red} loading="lazy" width={45} height={32} src={youtube_icon_red} alt="youtube icon red icon" />
                          </div>
                          <div className={styles.youtube_iframe_img}>
                            <Image
                              loading='lazy'
                              key={index}
                              src={video?.url}
                              width={640}
                              height={480}
                              alt={video.label}
                              onClick={() => showYoutube(video?.video_content?.video_url, `youtube_${index}`)}
                              placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" />
                          </div>
                        </div>
                      )}
                    </PCarousel>
                  ) : (
                    ""
                  )}

                  <div className={styles.icon_shoping}>
                    <ul>
                      <li className={styles.wishlist_box}>
                        <a>
                          <WishlistButton
                            product={product}
                            name={product?.name}
                            parentSku={product?.sku}
                            sku={childsku}
                            variantLabel={variantLabel}
                            eventCommonObj={eventCommonObj}
                            productprice={saleproductprice}
                            productSellPrice={productprice}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`col-12 col-md-6 p-col-0 ${styles['p-col-0']}`}>
                <div className={`gallery ${styles["gallery"]}`}>
                  {product && mediaList ? (
                    <PCarousel
                      showArrows
                      showStatus={false}
                      showIndicators
                      showThumbs
                      autoPlay={false}
                      transitionTime={500}
                      emulateTouch
                      swipeable
                      draggable
                      swipeScrollTolerance={5}
                      preventMovementUntilSwipeScrollTolerance
                      showDots={false}
                      renderThumbs={renderCustomThumbs}
                    >
                      {zoomimgs?.map((image, index) =>
                        <span key={index}>
                          <a className="zoom-icon" onClick={() => zoomopen(true, index)}>
                            <img priority={index == 0 || false} width={28} height={28} src={zoomNew} alt="zoom icon" />
                          </a>
                          <Image
                            className={styles.pdpBanner}
                            onDoubleClick={() => zoomopen(true, index)}
                            loading={index === 0 ? 'eager' : 'lazy'}
                            width={455}
                            height={455}
                            src={image}
                            alt={product.name} />
                          {/* Keep this for future ref - incase anything breaks */}
                          {/* <Image
                          priority={index == 0 || false}
                          src={image}
                          width={455}
                          height={455}
                          onDoubleClick={() => zoomopen(true, index)}
                          alt={product.name}
                          placeholder="blur"
                          blurDataURL="/images/kama-placeholder-kama.jpg" /> */}
                        </span>
                      )}
                      {zoomvideos?.map((video, index) =>
                        <div key={index} className={styles.youtube_iframe} id={`youtube_${index}`}>
                          <div className={styles.youtube_icon}>
                            <img className={styles.youtube_icon_red} loading="lazy" width={45} height={32} src={youtube_icon_red} alt="youtube icon red icon" />
                          </div>
                          <div className={styles.youtube_iframe_img}>
                            <Image
                              loading='lazy'
                              key={index}
                              src={video?.url}
                              width={640}
                              height={480}
                              alt={video.label}
                              onClick={() => showYoutube(video?.video_content?.video_url, `youtube_${index}`)}
                              placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" />
                          </div>
                        </div>
                      )}
                    </PCarousel>
                  ) : (
                    ""
                  )}

                  <div className={styles.icon_shoping}>
                    <ul>
                      <li className={styles.wishlist_box}>
                        <a>
                          <WishlistButton
                            product={product}
                            name={product?.name}
                            parentSku={product?.sku}
                            sku={childsku}
                            variantLabel={variantLabel}
                            eventCommonObj={eventCommonObj}
                            productprice={saleproductprice}
                            productSellPrice={productprice}
                          />
                        </a>
                      </li>
                      <li className={styles.share_box}>
                      <a onClick={() => openShare(product)}>&nbsp;</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {product?.layout_switch ? <NewInfoSectionMemorized
              enableVariantData={enableVariantData}
              productDetails={productDetails}
              product={product}
              isMobileType={isMobileType}
              openShare={openShare}
              handleShareClick={handleShareClick}
              saleProductPrice={saleproductprice}
              productPrice={productprice}
              currency={currency}
              config={config}
              dec={dec}
              inc={inc}
              count={count}
              productsize={productsize}
              selectSize={selectSize}
              buyToBag={buyToBag}
              childsku={childsku}
              BuyNowButtonText={BuyNowButtonText}
              addToBag={addToBag}
              ButtonText={ButtonText}
              showPDPMsg={showPDPMsg}
              websiteID={websiteID}
              part={part}
              showTermsModal={showTermsModal}
              setShowLoader={setShowLoader}
              eventCommonObj={eventCommonObj}
            /> :
              <div className={`col-12 col-md-6 ${styles['col-md-6']}`}>
                {/* Product details like name, description and reviews number */}
                <div className={styles.pdp_sec1}>
                  <h1>{enableVariantData ? (productDetails?.productName || product.name) : product.name}</h1>

                  {!isMobileType ? (
                    <div className={styles.star_rating}>
                      <ul>
                        {+(product.rating_summary / 20).toFixed(1) > 2 ? (
                          <>
                            <li className={styles.pdp_star_color}>
                              {(product.rating_summary / 20).toFixed(1)}{" "}
                              <img loading='lazy' src={star_white} alt="star icon" width={11} height={10} />
                            </li>
                            <li>
                              <Link href="#reviewform">
                                {product.review_count} Reviews
                              </Link>
                            </li>
                          </>
                        ) : null}
                        <li>
                          <Link href="#reviewform">
                            Write a Review
                          </Link>
                        </li>
                        <li className={styles.share_user}>
                          <a onClick={() => openShare(product)}>
                            <span>Share </span>
                          </a>
                          <a onClick={handleShareClick} target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://www.kamaayurveda.in/${product?.url_key}.html`}>
                            <img
                              loading='lazy'
                              src={facebook}
                              width="11"
                              height="12"
                              alt="facebook icon"
                            />
                          </a>
                          <a onClick={handleShareClick} target="_blank" href={`https://twitter.com/intent/tweet?url=https://www.kamaayurveda.in/${product?.url_key}.html&text=I love this product on Kama Ayurveda!`}>
                            <img
                              loading='lazy'
                              src={twitter}
                              width="11"
                              height="12"
                              alt="twitter icon"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : null}

                  <p className={styles.origin}>Country of Origin: India</p>

                  <div className={styles.pwa_desc}>
                    {(enableVariantData && productDetails?.productDescription) ? (
                      <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: productDetails?.productDescription || product.pwa_description,
                        }}
                      />
                    ) : product?.pwa_description ? (
                      <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: product.pwa_description,
                        }}
                      />
                    ) : null}

                  </div>

                  {isMobileType ? (
                    <ul className={styles.starReviewSec}>
                      {+(product.rating_summary / 20).toFixed(1) > 2 ? (
                        <>
                          <li className={styles.pdp_star_color}>
                            {" "}
                            {(product.rating_summary / 20).toFixed(1)} <img src={star_white} alt="star icon" loading='lazy' width={11} height={10} />
                          </li>
                          <li><Link href="#reviewform">{product.review_count}Reviews</Link></li>
                        </>
                      ) : null}
                      <li>
                        <Link href="#reviewform">
                          Write a Review
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </div>

                {/* Price and qty update section */}
                {product.stock_status == "IN_STOCK" && (!product.salableqty.label || product.salableqty.label > 0) ?
                  <div className={styles.pdp_price}>
                    <div className={styles.pdp_sec17}>
                      <div className="row">
                        <div className="col-7 pe-0">
                          <div className={styles.h4pdp_price}>
                            {(saleproductprice > productprice) ? (
                              <>
                                <span>MRP</span>
                                {`${currency}${productprice.toFixed(2)}`}
                                <abbr className={styles.cutoffprice}>
                                  {`${currency}${saleproductprice.toFixed(2)}`}
                                </abbr>
                              </>
                            ) : (
                              <>
                                <span>MRP</span>
                                {`${currency}${productprice.toFixed(2)}`}
                              </>
                            )}
                          </div>
                          <div className={styles.h6pdp_price}>
                            (incl. of all taxes)
                          </div>
                          {
                            (product.expiry_date?.label) ? <p className={styles.col_expiry}><strong>Expiry Date:</strong> {product.expiry_date.label}</p> : ''
                          }
                        </div>
                        {(!config.anniversary_promotion8_text || !config.anniversary_promotion8_text.split(',').includes(product.sku)) ? <div className="col-5">
                          <div className={(product.variants && product?.primary_category?.toLowerCase() !== 'gifting') ? `${styles["listing_pdp"]}` : `${styles["listing_pdp"]} ${styles["listing_pdp_simple"]}`}>
                            <abbr>Quantity</abbr>
                            <div className={styles.number}>
                              <span onClick={dec} className={styles.minus}>
                                -
                              </span>
                              <small className={styles.main_number}>{count}</small>
                              <span onClick={inc} className={styles.plus}>
                                +
                              </span>
                            </div>
                          </div>
                        </div> : null
                        }
                      </div>
                    </div>{" "}
                  </div> : null
                }

                {/* Variants section */}

                <NetQuantityMemoized styles={styles} product={product} productSize={productsize} selectSize={selectSize} />

                {websiteID == 1 ? (
                  <div className={styles.snapmintDes}>
                    <div className="snap_emi_txt"></div>
                    <span
                      className="snapmint_lowest_emi_value hidden"
                      style={{ display: 'none' }}
                      data-snapmint-price={productprice * count}
                      data-snapmint-subvention="false"
                      data-snapmint-merchant_id="2850"
                      data-snapmint-page="product">
                    </span>
                  </div>
                ) : null}

                {/* simpl text and image */}
                {(websiteID == 1 ?
                  <section className={styles.simpl_text}>
                    Or 3 interest free payments of
                    <strong> ₹{part}</strong> with
                    <a onClick={(e) => showTermsModal(e)} >
                      <span>
                        <img src={simpl_icon} alt='simpl' loading='lazy' width={60} height={19} />
                      </span>
                      <abbr>
                        <img loading='lazy' src={info} alt='info' width={15} height={15} />
                      </abbr>
                    </a>
                  </section> : null)}

                <ATCBuyNowButton>
                  <div className={styles.pdp_sec2}>
                    {(product.stock_status == "OUT_OF_STOCK" || (product.salableqty.label && product.salableqty.label <= 0)) ? (
                      <ul className="col-12">
                        <li className={styles.out_of_stock_btn}>
                          <button type="button" disabled>
                            Out Of Stock
                          </button>
                        </li>
                      </ul>
                    ) : null}

                    {(product.stock_status == "IN_STOCK" && (!product.salableqty.label || product.salableqty.label > 0)) ? (
                      <ul>
                        {(config?.anniversary_promotion8_text && config?.anniversary_promotion8_text?.split(',').includes(product.sku) && config.anniversary_promotion8_title) ?
                          ('') : (
                            <li>
                              <form onSubmit={buyToBag}>
                                {product.variants ? <input
                                  type="hidden"
                                  name="parentsku"
                                  defaultValue={product.sku}
                                /> : null
                                }
                                <input
                                  type="hidden"
                                  name="sku"
                                  defaultValue={product.variants ? childsku : product.sku}
                                />
                                <input type="hidden" name="qty" defaultValue={count} />

                                <button type="submit">{BuyNowButtonText}</button>
                              </form>
                            </li>
                          )}

                        {(config?.anniversary_promotion8_text && config?.anniversary_promotion8_text?.split(',').includes(product.sku) && config.anniversary_promotion8_title) ?
                          ('') :
                          (<li>
                            <form onSubmit={addToBag}>
                              {product.variants ? <input
                                type="hidden"
                                name="parentsku"
                                defaultValue={product.sku}
                              /> : null
                              }
                              <input
                                type="hidden"
                                name="sku"
                                defaultValue={product.variants ? childsku : product.sku}
                              />
                              <input type="hidden" name="qty" defaultValue={count} />

                              <button type="submit"><b><img className={styles.bag_align_middle} loading='lazy' src={desk_bag_white} alt="bag icon" width={16} height={19} /></b>  {ButtonText}</button>
                            </form>{" "}
                          </li>)}
                      </ul>
                    ) : null}
                  </div>
                </ATCBuyNowButton>
              </div>}
          </div>
        </div>
      </div>

     {product?.layout_switch && <OfferAndRewardsMemoized pdpOffers={pdpOffers} />}

      {product?.layout_switch ? <div className="container_productitem">
        <YouthGlowmemorized
          product={product}
          isMobileType={isMobileType}
        />

        {/* Lively PIP PDP */}
        <div id="render_lively_html_content" brand_id="7a94329b88" flow="h0kl3" pip="1" style={{ zIndex: 1000 }}></div>

        {/* Lively Carousel Home */}
        <div className={`container ${styles.livelyContainer}`}>
          <div className="render_lively_html_content" brand_id="7a94329b88" flow="h0kl3" pip="3" style={{ zIndex: "inherit" }} layout="8a1aaececb"></div>
        </div>

        <SaffronMemorized
          product={product}
          isMobileType={isMobileType}
        />

        <HowToApplyMemoized product={product} isMobileType={isMobileType} />

        <RoutineProduct product={product} currency={currency} />

        <OverviewSectionMemoized product={product} />

      </div> : null}

      {/* Pincode Amaya Offer Section */}
      {!product?.layout_switch ? <div className={`container`}>
        <div className={styles.desk_pdp_sec2}>
          <OfferPincodeMemoized
            enableVariantData={enableVariantData}
            productClinicallyTested={productDetails?.productClinicallyTested}
            productHowToUse={productDetails?.productHowToUse}
            websiteID={websiteID}
            setShowLoader={setShowLoader}
            eventCommonObj={eventCommonObj}
            anniversaryText={config?.anniversary_promotion8_text}
            product={product}
            productprice={productprice}
            count={count}
            pdpOffers={pdpOffers}
            modalStyle={modalStyle}
            classes={classes}
            isMobileType={isMobileType}
          />
        </div>

        {/* Youtube and content Section */}
        {
          (enableVariantData && productDetails?.productHowToUseVideo) ? (
            <div className={styles.pdp_sec20}>
              <YoutubeContentSection videoURL={productDetails?.productHowToUseVideo || product?.pwa_how_to_use_video} howToUse={product?.how_to_use_content} />
            </div>
          ) : (
            product?.pwa_how_to_use_video ?
              <div className={styles.pdp_sec20}>
                <YoutubeContentSection videoURL={product?.pwa_how_to_use_video} howToUse={product?.how_to_use_content} />
              </div>
              : null)
        }
      </div> : null}

      {/* Ingredients Section */}
      {!product?.layout_switch ? <IngredientsMemoized styles={styles} isMobileType={isMobileType} urlKey={product?.url_key} productId={product?.id} productIngredientId={product?.ingredients_id} /> : null}

      {/* Benefites Section */}
      {
        (!product?.layout_switch && enableVariantData && productDetails?.productBenefits) ? (
          <PWABenefitsSection productPWABenifit={productDetails?.productBenefits} />
        ) :
          !product?.layout_switch && product?.pwa_benefits ?
            <PWABenefitsSection productPWABenifit={product.pwa_benefits} />
            : null
      }

      {/* Use It With / Buy it With Product Section */}
      <div className="container">
        {!product?.layout_switch && buyitwithproducts?.filter(buyitwithproduct => buyitwithproduct?.type_id === "simple" && buyitwithproduct?.websiteid == websiteID)?.length > 0 ? (
          <div className={styles.pdp_sec9}>
            <div className={styles.h2pdp_sec9}>Use It With</div>
            <BuyItWithProductsMemoized
              eventCommonObj={eventCommonObj}
              buyitwithproducts={buyitwithproducts}
              product={product}
              currency={currency}
              productprice={productprice}
              addCrossSellToBag={addCrossSellToBag}
              checkedState={checkedState}
              handleOnChange={handleOnChange}
              childsku={childsku}
              count={count}
              crosssellCount={crosssellCount}
              websiteID={websiteID}
            />
          </div>
        ) : null}

        {/* OurCertifcatesSection Comp */}
        {(!product?.layout_switch && enableVariantData && productDetails?.productCertifications) ?
          <OurCertifcatesSection productCertificate={productDetails?.productCertifications} /> :
          !product?.layout_switch && product?.pwa_certifications ?
            <OurCertifcatesSection productCertificate={product.pwa_certifications} />
            : null}

        {/* Recommended/Related Product Comp */}
        {product?.layout_switch ?
          <RecommnededProductsMemoized
            urlKey={urlKey}
            websiteID={websiteID}
            isMobileType={isMobileType}
            eventCommonObj={eventCommonObj}
          />
          : <div className={styles.pdp_sec11}>
            <RelatedProductsMemoized
              urlKey={urlKey}
              websiteID={websiteID}
              isMobileType={isMobileType}
              eventCommonObj={eventCommonObj}
            />
          </div>}
      </div>

      {/* Success Stories Comp */}
      {
        !product?.layout_switch && product?.success_stories ? (
          <div className={styles.pdp_sec19} >
            <div className="container">
              <div className={styles.h2pdp_sec19}>Success Stories</div>

              <div
                className={styles.pdp_sec11_row}
                dangerouslySetInnerHTML={{
                  __html: product.success_stories,
                }}
              />
            </div>
          </div>
        ) : null
      }

      {product?.layout_switch && <NewProductReviewComponent
          preFixUrl={preFixUrl}
          classes={classes}
          setShowLoader={setShowLoader}
          productcategory={productcategory}
          eventCommonObj={eventCommonObj}
          product={product}
          currency={currency}
        /> }

      {product?.layout_switch && <FAQRedesignSectionMemoized isMobileType={isMobileType} preFixUrl={preFixUrl} productID={product?.id} urlKey={product?.url_key} />}

      <div className={product?.layout_switch ? 'container_productitem' : 'container'}>
        {/* Review Comp */}
        {!product?.layout_switch && <ProductReviewComponent
            styles={styles}
            preFixUrl={preFixUrl}
            classes={classes}
            setShowLoader={setShowLoader}
            productcategory={productcategory}
            eventCommonObj={eventCommonObj}
            product={product}
            currency={currency}
          /> 
        }

        {/* FAQ Section */}
        {!product?.layout_switch && <FAQSectionMemoized preFixUrl={preFixUrl} productID={product?.id} urlKey={product?.url_key} />}

        {/* Add Ques Comp */}
        {!product?.layout_switch && <AddQuestionMemoized preFixUrl={preFixUrl} setShowLoader={setShowLoader} productID={product?.id} />}

        {/* Blog Component */}
        {product?.layout_switch ? <ExploreBlogMemorized urlKey={product?.url_key} blogId={product?.blog_ids} preFixUrl={preFixUrl} eventCommonObj={eventCommonObj} isMobileType={isMobileType} />
          :
          <KamaBlogMemoized styles={styles} urlKey={product?.url_key} blogId={product?.blog_ids} preFixUrl={preFixUrl} eventCommonObj={eventCommonObj} isMobileType={isMobileType} />}

        {/* Product Info Comp */}
        {product?.layout_switch ? <ProductInformation productInfoData={product?.product_info_attributes} /> : null}

        {/* Recently viewed comp */}
        {!product?.layout_switch && typeof recentview != "undefined" && recentview?.length > 2 ? (
          <div className={styles.pdp_sec15}>
            <div className={styles.h2pdp_sec15}>Recently Viewed</div>
            <RecentlyViewedMemoized recentview={recentview} productID={product?.id} preFixUrl={preFixUrl} currency={currency} isMobileType={isMobileType} />
          </div>
        ) : null}
      </div >
    </ >
  );
};