import { makeStyles } from "@material-ui/core";
import Router from "next/router";
import { useRouter } from "next/router";
import { parseCookies } from "nookies";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { initializeStore } from "/components/store/store";
import { getWebsiteID } from "/lib/common-functions";
import { AddToBagEvent, AddtoBagOfferEvent, BeginCheckout, CheckPincodeClick, ClosePopupClick, convertCartItemsToProductFormat, KnowMoreAmayaClick, ProductImpression, ProductReviewSubmit, ViewProduct } from "/lib/ga";
import { useIsMobile } from "/lib/hooks/useIsMobile";
import { checkCart } from "/lib/magento-api";
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { round } from "/lib/services/helpers/roundTwoDecimal";
import { getReduxSession } from "/lib/sessionHandler";
import { loadLively } from "/lib/hooks/loadLively";
import { loadScript } from "/lib/services/helpers/loadscript";
import { getBreadCrumbURL } from "/lib/services/helpers/getWebsiteURL";
import { getStoreConfigData } from "/lib/apis/getStoreConfig";

function openShare(product) {
  navigator
    .share({
      title: product?.name,
      text: product?.name,
      url: window.location.href,
    })
    .then(() => console.log("Successful share! 🎉"))
    .catch((err) => console.error(err));
}

function afterLoading(ele) {
  var iframe = document.getElementById(ele);
}

function checkIframeLoaded(iframeid) {
  // Get a handle to the iframe element
  var iframe = document.getElementById(iframeid);
  var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

  // Check if loading is complete
  if (iframeDoc.readyState == 'complete') {
    //iframe.contentWindow.alert("Hello");
    iframe.contentWindow.onload = function () {

    };
    // The loading is complete, call the function we want executed once the iframe is loaded
    afterLoading(iframeid);
    return;
  }

  // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
  window.setTimeout(checkIframeLoaded, 100);
}

function showYoutube(url, ele) {
  const newUrl = `${url}?autoplay=1`
  // const newUrl = url.replace('watch', "embedd");
  document.getElementById(ele).innerHTML = ''
  document.getElementById(ele).innerHTML = `<iframe
    width="100%"
    height="455"
    id="`+ ele + `iframe"
    src="`+ newUrl + `"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    autoplay=true
    title="Embedded youtube"
    loading="lazy"
  />`
  checkIframeLoaded(`${ele}iframe`)
  document.getElementById(ele).click()
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  paper: {
    position: "absolute",
    width: "100%",
    padding: "15px",
  },
}));

export const useProduct = ({ product, isSsrMobile, enableVariantData }) => {
    const session = getReduxSession();
    const [cookie, setCookie] = useCookies(["store"]);
    const [utmsource, setUtmsource] = useCookies(["utm_source"]);
    const isMobileType = useIsMobile(isSsrMobile)
    const reduxStore = initializeStore();
    const router = useRouter();
    const classes = useStyles();
    const { asPath, pathname } = router;
    const { dispatch } = reduxStore;

    const cookies = parseCookies(null, "fromClient");

    const [productDetails, setProductDetails] = useState({
      productName: product?.name,
      productDescription: product?.pwa_description,
      productClinicallyTested: product?.pwa_clinically_tested,
      productHowToUse: product?.pwa_how_to_use,
      productHowToUseVideo: product?.pwa_how_to_use_video,
      productBenefits: product?.pwa_benefits,
      productCertifications: product?.pwa_certifications,
    });


    const [productprice, setProductprice] = useState(product?.price.regularPrice.amount.value);
    const [saleproductprice, setSaleProductprice] = useState(product?.price_range?.minimum_price.regular_price.value);
    const [mediaList, setMediaList] = useState(product?.media_gallery);
    const [productsize, setProductsize] = useState(0);
    const [childsku, setChildsku] = useState(0);
    const [productcategory, setProductcategory] = useState(null);
    const [variantLabel, setVariantLabel] = useState(null);
    const [checkedState, setCheckedState] = useState([]);
    const [crosssellCount, setCrosssellCount] = useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [ButtonText, setButtonText] = useState("Add to Bag");
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [cartMessage, setCartMessage] = useState("");
    const [bSnackbarVisible, setTriggerShowSnackbar] = useState(false);
    const [showPDPMsg, setShowPDPMsg] = useState(false);
    const [BuyNowButtonText, setBuyNowButtonText] = useState("Buy Now");
    const [count, setCount] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [pdpOffers, setPDPOfers] = useState(null);

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    // The state for our timer
    loadLively();
    const eventCommonObj = EventCommonObj(session, cookie)
    const config = useSelector((state) => state.configdata);
    let recentview = useSelector((state) => state.viewedProducts);
    const websiteID = useMemo(() => getWebsiteID(cookie), [cookie])
    const preFixUrl = useMemo(() => websiteID == 1 ? "" : "/international",[websiteID]);
    const breadCrumbUrl = getBreadCrumbURL(websiteID)
    const currency = useMemo(() => websiteID == 1 ? "₹" : "$",[websiteID]);
    
    const zoomimgs = useMemo(() => {
      return (mediaList) ? (mediaList
        ?.slice()
        ?.sort((a, b) => a?.position - b?.position)
        ?.map((image, index) =>
          image?.video_content == null ? image?.url : null)).filter(function (e) { return e != null; }) : null
    },[mediaList])

    const zoomvideos = useMemo(() => {
      return (mediaList) ? (mediaList
        ?.slice()
        ?.sort((a, b) => a?.position - b?.position)
        ?.map((image, index) =>
          image?.video_content != null ? image : null)).filter(function (e) { return e != null; }) : null
    }, [mediaList])

    const buyitwithproducts = product?.crosssell_products;
    const addbagapi = product?.variants ? "/api/addtobag" : "/api/addtobagsimple"
    recentview = useMemo(() => {
     return recentview.filter(function (item) {
        return typeof item !== 'undefined';
      });
    },[product?.url_key])

    var amount_in_paise = parseInt(round(count * productprice) * 100);
    var div = parseInt(Math.floor(amount_in_paise / 3));
    var rem = amount_in_paise - (div * 3);
    var part = parseInt(div) + parseInt(rem);
    var amount_in_rs = parseFloat(round(part / 100));
    var part = round(amount_in_rs);
    if (rem > 0) {
      part = Math.abs(round(amount_in_rs));
    }

    let canonicalurl = ''
    let currentSlug = asPath.replace(".html", "")
    currentSlug = currentSlug.replace(/\//g, '');

    if (product && product.variants && product.variants.length > 0) {
      product.variants.map(function (item) {
        if (item && item.product && item.product.url_key && item.product.url_key == currentSlug && item.product.canonicalurl && item.product.canonicalurl.label) {
          canonicalurl = item.product.canonicalurl.label
        }

      })
    }
    if (canonicalurl == '' && product && product.canonicalurl && product.canonicalurl.label) {
      canonicalurl = product.canonicalurl.label
    }

      const setCrossSellItemsCount = () => {
        if (buyitwithproducts?.length) {
          let stateCrossArray = [];
          let totalChecked = 0;
          buyitwithproducts.filter(buyitwithproduct => buyitwithproduct.type_id === "simple").map(function (item, index) {
            if (item.stock_status == "IN_STOCK" && !(item.salableqty.label != null && item.salableqty.label < 0)) {
              stateCrossArray.push(true);
              totalChecked = totalChecked + 1;
            } else {
              stateCrossArray.push(false);
            }
          });
          setCheckedState(stateCrossArray);
          setCrosssellCount(totalChecked);
        }
      }

      const addToBag = async (event) => {
        setShowLoader(true);
        event.preventDefault();
        setButtonText("Please Wait");

        try {
          const res = await fetch(addbagapi, {
            body: JSON.stringify(product.variants ? {
              parentsku: event.target.parentsku.value,
              sku: event.target.sku.value,
              qty: event.target.qty.value,
              cookie: cookies,
            } :
              {
                sku: event.target.sku.value,
                qty: event.target.qty.value,
                cookie: cookies,
              }
            ),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });
      
          const bagdata = await res.json();
  
          if (bagdata.message) {
            setOpen(true);
            setSuccessMessage(false);
            setCartMessage(bagdata.message);
            setTriggerShowSnackbar(true);
            setShowLoader(false);
            setButtonText("Add to Bag");
          } else {
            setButtonText("Added");
            setShowLoader(false);
            setOpen(true);
            setTriggerShowSnackbar(true);
            let cartLink = (websiteID == 1) ? "/checkout/cart" : "/international/checkout/cart"
            let cartMsg = `Product added to Bag! <a href="${cartLink}">VIEW BAG</a>`
            setCartMessage(cartMsg);
            setSuccessMessage(true);
            setShowPDPMsg(true)
            let Qty = bagdata.total ? bagdata.total : 1;
            dispatch({
              type: "INCREMENT",
              count: Qty,
            });
            setButtonText("Add to Bag");
           
            AddToBagEvent(product, productprice, saleproductprice, session, variantLabel, event.target.sku.value, event.target.qty.value, websiteID, eventCommonObj, '')
          
            if (utmsource['utm_source'] == 'Colombia') {
              function setAttributes(element, attributes) {
                Object.keys(attributes).forEach(attr => {
                  element.setAttribute(attr, attributes[attr]);
                });
              }
              const attributes = {
                id: 'timesimg',
                height: '1',
                width: '1',
                style: 'display:none',
              };
              var img = document.createElement("img");
              setAttributes(img, attributes);
              img.src = "https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=14381&_w=1&_t=1";
              var element = document.querySelector('head');
              element.appendChild(img);
              img.remove();
            }
          }
        } catch (error) {
          setShowLoader(false);
          setButtonText("Add to Bag");
          console.log('error in add to bag', error)
        }
      };

      const buyToBag = async (event) => {
        event.preventDefault();
        setBuyNowButtonText("Please Wait");

        try {
          const res = await fetch(addbagapi, {
            body: JSON.stringify(product.variants ? {
              parentsku: event.target.parentsku.value,
              sku: event.target.sku.value,
              qty: event.target.qty.value,
              cookie: cookies,
            } :
              {
                sku: event.target.sku.value,
                qty: event.target.qty.value,
                cookie: cookies,
              }
            ),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });
      
          const bagdata = await res.json();
          
          if (!res.ok) {
            return bagdata;
          }

          const result = await checkCart();
          const getCoupon = localStorage.getItem('coupon_applied')
          const checkout_cta = 'buy_now';

          if (result?.data?.cart?.items?.length > 0) {
            BeginCheckout({
              products: convertCartItemsToProductFormat(result?.data?.cart?.items),
              coupon: getCoupon || result?.data?.cart?.applied_coupons?.[0]?.code || 'N/A',
              totalCartValue: result?.data?.cart.prices?.grand_total?.value,
              session,
              websiteID: websiteID,
              eventCommonObj,
              checkout_cta
            })
          }

          setBuyNowButtonText("Added");
          dispatch({
            type: "INCREMENT",
            count: bagdata.total,
          });
      
          if (utmsource['utm_source'] == 'Colombia') {
            function setAttributes(element, attributes) {
              Object.keys(attributes).forEach(attr => {
                element.setAttribute(attr, attributes[attr]);
              });
            }
            const attributes = {
              id: 'timesimg',
              height: '1',
              width: '1',
              style: 'display:none',
            };
            var img = document.createElement("img");
            setAttributes(img, attributes);
            img.src = "https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=14381&_w=1&_t=1";
            var element = document.querySelector('head');
            element.appendChild(img);
            img.remove();
          }
  
          let redirectUrl = preFixUrl + "/checkout";
          Router?.push(redirectUrl);

        } catch (error) {
          console.log('error', error)
        }
       
      };

      const addCrossSellToBag = async (event) => {
        event.preventDefault();
        setShowLoader(true);
        
        try {
          const items = event.target.crosssell;
          let crosssellproduct = [];

          if (NodeList.prototype.isPrototypeOf(items)) {
            let myArray = Array.from(items);
      
            myArray?.map(function (item, index) {
              if (item?.checked) {
                crosssellproduct[index] = item?.value;
              }
            });
          } else {
            if (items.checked) {
              crosssellproduct[0] = items.value;
            }
          }

          crosssellproduct = crosssellproduct.filter(function (el) {
            return el != null;
          });
      
          const res = await fetch(addbagapi, {
            body: JSON.stringify(product?.variants ? {
              parentsku: event.target.parentsku.value,
              sku: event.target.sku.value,
              qty: event.target.qty.value,
              cookie: cookies,
              crosssell: crosssellproduct,
            } : {
              parentsku: event.target.parentsku.value,
              sku: event.target.sku.value,
              qty: event.target.qty.value,
              cookie: cookies,
              crosssell: crosssellproduct,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          });
      
          const bagdata = await res.json();

          if (bagdata.message) {
            setOpen(true);
            setSuccessMessage(false);
            setCartMessage(bagdata.message);
            setTriggerShowSnackbar(true);
            setShowLoader(false);
            setButtonText("Add to Bag");
          } else {
            const filteredProducts = buyitwithproducts?.filter(buyitwithproduct => buyitwithproduct?.type_id === "simple" && buyitwithproduct.websiteid == websiteID && crosssellproduct.includes(buyitwithproduct.sku))
            const getCoupon = localStorage.getItem('coupon_applied') || ''
           
            AddtoBagOfferEvent(filteredProducts, getCoupon, websiteID, eventCommonObj)
            setShowLoader(false);
            setOpen(true);
            setTriggerShowSnackbar(true);
           
            let cartLink = (websiteID == 1) ? "/checkout/cart" : "/international/checkout/cart"
            let cartMsg = `Product added to Bag! <a href="${cartLink}">VIEW BAG</a>`
           
            setCartMessage(cartMsg);
            setSuccessMessage(true);        
            setButtonText("Added");
           
            let Qty = bagdata.total ? bagdata.total : 1;
            dispatch({
              type: "INCREMENT",
              count: Qty,
            });
          }
        } catch (error) {
          setShowLoader(false);
          setButtonText("Add to Bag");
          console.log('error while adding crossel product', error);
        }
      };

      const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
        let totalChecked = 0;
        updatedCheckedState.map(function (item, index) {
          totalChecked = item == true ? totalChecked + 1 : totalChecked;
        });
        setCrosssellCount(totalChecked);
        setCheckedState(updatedCheckedState);
      };
    
      const handleSnackClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setTriggerShowSnackbar(false);
      };
  
      const handleCloseModal = useCallback(() => {
        setShowModal(false);
      },[])
    
      const showTermsModal = (e) => {
        setShowModal(true);
      };

      const zoomopen = useCallback((value, index) => {
        setIsOpen(value);
        setPhotoIndex(index);
      },[])

      const navigate = useCallback((value) => {
        setPhotoIndex(value);
      },[])

      const inc = () => {
        setCount(count + 1);
      };
    
      const dec = () => {
        if (count > 1) {
          setCount(count - 1);
        }
      };

      useEffect(() => {
        const loadSnapmintScript = async() => {
         await loadScript(`${('https:' == document.location.protocol ? 'https://' : 'http://')}${'preemi.snapmint.com/assets/merchant/2850/snapmint_emi.js'} `)
        }
        if(websiteID == 1){
          loadSnapmintScript()
        }
      },[])

      useEffect(() => {
        if (window['Snap_t']) window['Snap_t']();

        const getPDPOffers = async() => {
          try {
            const { data } = await getStoreConfigData(websiteID == 1 ? "" : "international");
            setPDPOfers(JSON.parse(data?.storeConfig?.homepage_banner_offers_pdp))
          } catch (error) {
              console.log('error', error);
          }
        }

        getPDPOffers();

      },[productprice,count])

      useEffect(() => {
        setCrossSellItemsCount(buyitwithproducts);
        let temp = 0;
        let recentViewPrice = 0

        if(product?.url_key == 'kumkumadi-youth-revitalising-facial-oil' && product?.variants.length > 0){
          product?.variants?.map((options, index) => {
            if (options.product.stock_status == "IN_STOCK" && (!options.product.salableqty.label || options.product.salableqty.label > 0) && temp == 0) {
              if(options.product.sku == "ITM07619"){
                setProductsize(index);
                setProductprice(options.product.price.regularPrice.amount.value);
                recentViewPrice = options.product.price.regularPrice.amount.value
                selectSize(null, options.product.price.regularPrice.amount.value, index, options.product.price_range?.minimum_price.regular_price.value);
                temp++;  
              }
            }
          });
        }

        product?.variants?.map((options, index) => {
          if (options.product.stock_status == "IN_STOCK" && (!options.product.salableqty.label || options.product.salableqty.label > 0) && temp == 0) {
            setProductsize(index);
            setProductprice(options.product.price.regularPrice.amount.value);
            recentViewPrice = options.product.price.regularPrice.amount.value
            selectSize(null, options.product.price.regularPrice.amount.value, index, options.product.price_range?.minimum_price.regular_price.value);
            temp++;
          }
        });
        if (product && (product.stock_status == "OUT_OF_STOCK" || (product.salableqty.label && product.salableqty.label <= 0))) {
          setProductprice(
            product && product.variants && product.variants[0]
              ? product?.variants[0].product.price.regularPrice.amount.value
              : 0
          );
        }
        if (product && product.stock_status == "IN_STOCK" && !product.variants && (!product.salableqty.label || product.salableqty.label > 0)) {
          setProductprice(product?.price.regularPrice.amount.value);
          setSaleProductprice(product?.price_range?.minimum_price.regular_price.value)
        }
    
        dispatch({
          type: "ADD_PRODUCT",
          id: product?.id,
          sku: product?.sku,
          name: product?.name,
          url_key: product?.url_key,
          url_path: product?.url_path,
          image: product?.small_image.url,
          price: productprice,
          count: 1,
        });
    
        dispatch({
          type: "VIEWED_PRODUCT",
          product: {
            id: product?.id,
            sku: product?.sku,
            name: product?.name,
            url_key: product?.url_key,
            url_path: product?.url_path,
            image: product?.small_image.url,
            price: recentViewPrice,
            count: 1,
            description: ''
          },
        });
    
        window.data_3070 = [];
        window.data_3070.push({
          'PageType': 'product',
          'ProductId': product?.id,
          'ProductSKU': product?.sku,
          'ProductName': product?.name,
          'Price': productprice,
          'Currency': websiteID == 1 ? "INR" : "USD",
        });
        (function (d) {
          var s = d.createElement('script'); s.async = true; s.id = 'DLFNPMMP1'; s.type = 'text/javascript';
          s.src = 'https://cdn.preciso.net/aud/clientjs/ptag.js?3070';
          var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
        }(document));
      }, [product?.id]);

      useEffect(() => {
          const timeout = setTimeout(() => {
            const selectSku = product.variants ? childsku : product.sku
            return ViewProduct(product, selectSku, variantLabel, session, websiteID, eventCommonObj)
          }, 3000);
        
            return () => clearTimeout(timeout);
      }, [product?.url_key]);
      
      function selectSize(sku, price, index, mprice) {
        setProductprice(price);
        setSaleProductprice(mprice)
        setProductsize(index);
        setChildsku(product?.variants[index].product.sku);
        setProductcategory(product?.variants[index].product.primary_category);
        setVariantLabel(product?.variants[index]?.attributes[0]?.label);
        setMediaList(product?.variants[index].product.media_gallery);

        enableVariantData && setProductDetails({
          ...productDetails,
          productName: product?.variants[index].product.name,
          productDescription: product?.variants[index].product?.pwa_description,
          productClinicallyTested: product?.variants[index].product?.pwa_clinically_tested,
          productHowToUse: product?.variants[index].product?.pwa_how_to_use,
          productHowToUseVideo: product?.variants[index].product?.pwa_how_to_use_video,
          productBenefits: product?.variants[index].product?.pwa_benefits,
          productCertifications: product?.variants[index].product?.pwa_certifications,

        });

      }

      const handleShareClick = async () => {
        const gaModule = await import('/lib/ga');
        gaModule.ShareClick(product?.name, productcategory, eventCommonObj);
      }

      return {
        router,
        breadCrumbUrl,
        currency,
        preFixUrl,
        websiteID,
        cookie,
        session,
        utmsource,
        eventCommonObj,
        productprice,
        saleproductprice,
        productsize,
        childsku,
        productcategory,
        variantLabel,
        mediaList,
        buyitwithproducts,
        isMobileType,
        config,
        checkedState,
        crosssellCount,
        zoomvideos,
        zoomimgs,
        showLoader,
        open,
        successMessage,
        cartMessage,
        bSnackbarVisible,
        showPDPMsg,
        BuyNowButtonText,
        ButtonText,
        showModal,
        photoIndex,
        isOpen,
        count,
        classes,
        asPath,
        recentview,
        part,
        canonicalurl,
        modalStyle,
        productDetails,
        pdpOffers,
        setShowLoader,
        setButtonText,
        setTriggerShowSnackbar,
        addToBag,
        buyToBag,
        addCrossSellToBag,
        handleOnChange,
        handleSnackClose,
        handleCloseModal,
        showTermsModal,
        zoomopen,
        navigate,
        inc,
        dec,
        showYoutube,
        openShare,
        handleShareClick,
        setProductsize,
        setSaleProductprice,
        setProductprice,
        selectSize,
      }

}